/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>

    <div className="S-body">
      {children}
    </div>

    <footer className="u-shadow">
      <ul className="S-navList">
        <li className="u-link"><AniLink fade duration={.25} to="/">Home</AniLink></li>
        <li className="u-link"><AniLink fade duration={.25} to="/writing">Writing</AniLink></li>
        <li className="u-link"><AniLink fade duration={.25} to="/about">About</AniLink></li>
      </ul>
    </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
